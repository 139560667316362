import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import { ToastService } from 'src/app/services/toast.service';
import { ToastMessageType, ErrorMessage } from '../models/enum';
import { CommonService } from './common.service';
import { GlobalSettings } from 'src/app/global.settings';
import { Data } from '../models/data';
import { ArrayToObject } from 'src/app/util/arrayHelper';
import { Router } from '@angular/router';
@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  public ErrorMessage:any;
    static intercept(httpRequestSpy: any, httpHandlerSpy: any) {
      throw new Error('Method not implemented.');
    }

    excludeUrls = [
      'glossary.json$',
      'search_indexes.json',
      'thresholdValues.json',
      'thresholdValues.json',
      'api/login/checkKMLIStatus',
      'api/setLanguage'
    ];
    
     constructor(private toastService: ToastService, public commonService:CommonService,private _data:Data, private router: Router) { 
      if(this._data?.languageJson[GlobalSettings?.defultLang]?.length){
        this._data.languageJson = ArrayToObject(GlobalSettings?.defultLang, this._data?.languageJson);
      }
      this.ErrorMessage=this._data?.languageJson?.globalError;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const currentUrl = this.router.url;
      for(let url of this.excludeUrls) {
            let regExUrl = new RegExp(url);
            if(request.url.match(regExUrl)) {
             return next.handle(request)
              .pipe(
               map(res => {
                 return res;
               }),
               catchError((error: HttpErrorResponse) => {
                if(request.url.match(new RegExp('glossary.json$'))){
                  return throwError(() => ({url:request.url,status:error.status}));
                }else{
                  return throwError(() => request.url);
                }
               })
              );
            }
           }
        
        return next.handle(request)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError((error: HttpErrorResponse) => {
                  let errorMsg: string='';
                  this.ErrorMessage = this._data?.languageJson?.globalError;
                  if (error.error && error.error.message) {
                      errorMsg = `An error occurred: ${error.error.message}`;
                  } else {
                      switch (error.status) {
                        case 400:
                          errorMsg = this.ErrorMessage?.ERR400 ? this.ErrorMessage?.ERR400 : ErrorMessage?.ERR400;
                          break;
                        case 401:
                          errorMsg = this.ErrorMessage?.ERR401 ? this.ErrorMessage?.ERR401 : ErrorMessage?.ERR401;
                          break;
                        case 403:
                          errorMsg = this.ErrorMessage?.ERR403 ? this.ErrorMessage?.ERR403 : ErrorMessage?.ERR403;
                          break;
                        case 404:
                          errorMsg = this.ErrorMessage?.ERR404 ? this.ErrorMessage?.ERR404: ErrorMessage?.ERR404;
                          break;
                        case 412:
                          errorMsg = this.ErrorMessage?.ERR412 ? this.ErrorMessage?.ERR412 : ErrorMessage?.ERR412;
                          break;
                        case 500:
                          errorMsg = this.ErrorMessage?.ERR500 ? this.ErrorMessage?.ERR500 : ErrorMessage?.ERR500;
                          break;
                        case 502:
                            errorMsg = this.ErrorMessage?.ERR502 ? this.ErrorMessage.ERR502 : ErrorMessage.ERR502;
                            break;  
                        case 503:
                          errorMsg = this.ErrorMessage?.ERR503 ? this.ErrorMessage?.ERR503 : ErrorMessage?.ERR503;
                          break;
                          case 504:
                            errorMsg = this.ErrorMessage?.ERR504 ? this.ErrorMessage?.ERR504 : ErrorMessage?.ERR504;
                            break;  
                        case 422:
                          errorMsg = this.ErrorMessage?.ERR422 ? this.ErrorMessage?.ERR422 : ErrorMessage?.ERR422;
                          break;
                      }
                  }
                  if(errorMsg!='' && !request.url.includes('thresholdValues') && 
                  (!request.url.includes('gettocmanifest') && !currentUrl.includes('course-details') && error.status != 500)){
                    this.toastService.showMessage(ToastMessageType.ERROR, errorMsg);
                    (!GlobalSettings.isNative)?this.commonService.globalSystemErrorPage$.next({showErrorPage:true,statusCode:error.status,errDetails:errorMsg}):null;
                  }
                  if(errorMsg!='' && !GlobalSettings.isBrowser){
                    this._data.isLoading.next(false);
                    this._data.isLoadingLgoin.next(false);
                    }
                  if(errorMsg!='' && !GlobalSettings.isBrowser && error.status == 401){
                    this.router.navigate(['/logout']);
                  }
                  return throwError(() => request.url);
              })
            );
    }
}
